<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-form ref="changePasswordForm">
        <app-form-field
          v-model.trim="changePasswordObj.oldPassword"
          :label="$t(`field.currentPassword`)"
          :hint="$t(`fieldHint.currentPassword`)"
          :rules="validator.passwordRules()"
          type="password"
        ></app-form-field>
        <app-form-field
          v-model.trim="changePasswordObj.newPassword"
          :label="$t(`field.newPassword`)"
          :hint="$t(`fieldHint.password`)"
          :rules="validator.passwordRules()"
          type="password"
        ></app-form-field>
        <app-form-field
          v-model.trim="changePasswordObj.confirmNewPassword"
          :label="$t(`field.confirmNewPassword`)"
          :hint="$t(`fieldHint.password`)"
          :rules="validator.confirmPasswordRules(changePasswordObj.newPassword, changePasswordObj.confirmNewPassword)"
          type="password"
        ></app-form-field>
        <app-button :action="this.changePassword" :title="$t(`button.submit`)"></app-button>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import stingFormat from 'string-format'
import { formValidator } from '@/util'
import { MEMBER_CHANGE_PASSWORD } from '@/store/member.module'

export default {
  name: 'appChangePassword',
  mounted() {
    this.$refs.changePasswordForm.reset()
  },
  data: () => ({
    validator: formValidator,
    stingFormat: stingFormat,
    retainSignIn: false,
    changePasswordObj: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  }),
  methods: {
    changePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        let changePasswordObj = this.changePasswordObj
        this.$store.dispatch(`${MEMBER_CHANGE_PASSWORD}`, { changePasswordObj })
      }
    }
  }
}
</script>

<style lang="scss"></style>
