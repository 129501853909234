<template>
  <div class="profile-page">
    <v-card :class="$vuetify.breakpoint.mdAndDown ? 'mobile_card' : 'desktop_card'">
      <v-card-title>
        <label class="title_color--text px-5 font-weight-bold text-left text-uppercase">{{
            $t(`label.account`)
          }}</label>
      </v-card-title>
      <div>
        <div class="profile-tab-list-container">
          <div class="profile-tab-list text-center">
            <div class="profile-tab-list-item" v-for="link in profileTab" :key="link.key">
              <v-btn :to="{ name: link.link }" exact-active-class="active white--text" height="auto"
                     min-height="auto"
                     class="text-center font-weight-bold my-0 pa-4 profile-tab-list-item-button">
                <label>{{ $t(`label.${link.key.toLowerCase()}`) }}</label>
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <div class="py-6">
        <v-form ref="profileForm">
          <div class="profile-page-form">
            <div class="pb-2">
              <app-form-field
                  v-model.trim="memberInfo.name"
                  :readonly="tempMemberInfo.name != '' && tempMemberInfo.name != null"
                  :label="$t(`field.realName`)"
                  :rules="validator.realnameRules()"
                  :hint="memberInfo.isNameVerified ? $t(`fieldHint.profileRealNameVerified`) : $t(`fieldHint.profileRealName`)"
                  :customClass="memberInfo.isNameVerified ? 'v-input--is-verify' : ''"
              ></app-form-field>
            </div>

            <div class="pb-2">
              <app-form-field v-model.trim="memberInfo.memberCode" :label="$t(`field.username`)"
                              :hint="$t(`fieldHint.profileUsername`)" :readonly="true"></app-form-field>
            </div>
            <div class="pb-2">
              <app-form-field v-model.trim="memberInfo.password" :label="$t(`field.password`)"
                              :hint="$t(`fieldHint.profilePassword`)" :readonly="true" type="password">
                <template v-slot:form-field-append-outer>
                  <app-button :title="$t(`label.edit`)"
                              customClass="buttonPrimary white--text body-2 mt-0"
                              :action="openChangePasswordDialog"></app-button>
                </template>
              </app-form-field>
            </div>
            <div class="pb-2">
              <app-form-field
                  v-model.trim="memberInfo.email.value"
                  :label="$t(`field.email`)"
                  :hint="memberInfo.email.is_verified ? $t(`fieldHint.profileEmailVerified`) : $t(`fieldHint.profileEmail`)"
                  :customClass="memberInfo.email.is_verified ? 'v-input--is-verify' : ''"
                  :rules="validator.emailRules()"
              >
                <template v-slot:form-field-append-outer>
                  <app-button
                      v-if="memberInfo.email != '' && !memberInfo.email.is_verified"
                      :title="$t(`label.verify`)"
                      customClass="buttonPrimary white--text body-2 mt-0"
                      :action="verifyMemberEmail"
                  ></app-button>
                </template>
              </app-form-field>
            </div>
            <div class="pb-2">
              <app-form-field
                  v-model.trim="memberInfo.mobile.value"
                  :label="$t(`field.phone`)"
                  :hint="memberInfo.mobile.is_verified ? $t(`fieldHint.profilePhoneVerified`) : $t(`fieldHint.profilePhone`)"
                  :customClass="memberInfo.mobile.is_verified ? 'v-input--is-verify' : ''"
                  :prefix="stringFormat('+{0}', '60')"
                  :rules="validator.mobileNumberRules(defaultLanguage)"
              ></app-form-field>
            </div>
            <div class="pb-2" v-if="false">
              <app-form-field
                  v-model.trim="memberInfo.line.value"
                  :label="$t(`field.messenger`)"
                  :rules="validator.messengerRules()"
                  :hint="memberInfo.line.is_verified ? $t(`fieldHint.profileMessengerVerified`) : $t(`fieldHint.profileMessenger`)"
                  :customClass="memberInfo.line.is_verified ? 'v-input--is-verify' : ''"
              ></app-form-field>
            </div>

            <app-button :title="$t(`label.save`)" :action="updateAccountInfo"></app-button>
          </div>
        </v-form>
      </div>
    </v-card>

    <app-dialog :dialogShow="changePasswordDialogShow" :max-width="600" :title="$t(`label.changePassword`)"
                :closeAction="this.closeChangePasswordDialog">
      <app-change-password></app-change-password>
    </app-dialog>
  </div>
</template>
<script>
import stringFormat from 'string-format'
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper, locale, errorCodeHelper, formValidator} from '@/util'
import {SHARED} from '@/constants/constants'
import AppChangePassword from '@/components/member/changePassword.vue'
import {
  MEMBER_DETAIL,
  MEMBER_RESET_CHANGE_PASSWORD_STORE,
  MEMBER_UPDATE_DETAIL,
  MEMBER_RESET_UPDATE_DETAIL_STORE,
  MEMBER_SEND_CONTACT_VERIFICATION_EMAIL,
  MEMBER_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE,
  MEMBER_UPDATE_EMAIL
} from '@/store/member.module'

export default {
  name: 'account',
  components: {
    AppChangePassword
  },
  data: () => ({
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    stringFormat: stringFormat,
    validator: formValidator,
    shared: SHARED,
    tempMemberInfo: {
      email: '',
      name: ''
    },
    allowUpdateMemberEmail: false,
    profileTab: [
      {
        key: 'account',
        link: ROUTE_NAME.PROFILE
      },
      {
        key: 'personal',
        link: ROUTE_NAME.PERSONAL
      }
    ],
    changePasswordDialogShow: false
  }),
  created() {
    this.getMemberDetail()
  },
  computed: {
    memberInfo() {
      return this.$store.state.member.info
    },
    changePasswordResponseComplete() {
      return this.$store.state.member.changePasswordResponse.complete
    },
    updateDetailResponseComplete() {
      return this.$store.state.member.updateDetailResponse.complete
    },
    sendContactVerificationEmailResponseComplete() {
      return this.$store.state.member.sendContactVerificationEmailResponse.complete
    }
  },
  watch: {
    memberInfo() {
      let info = this.$store.state.member.info
      this.tempMemberInfo.name = info.name
      return this.$store.state.member.info
    },
    changePasswordResponseComplete() {
      let response = this.$store.state.member.changePasswordResponse
      if (response.complete) {
        this.changePasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CHANGE_PASSWORD_STORE}`)
      }
    },
    updateDetailResponseComplete() {
      let response = this.$store.state.member.updateDetailResponse
      if (response.complete) {
        this.updateDetailResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_DETAIL_STORE}`)
      }
    },

    sendContactVerificationEmailResponseComplete() {
      let response = this.$store.state.member.sendContactVerificationEmailResponse
      if (response.complete) {
        this.sendContactVerificationEmailResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE}`)
      }
    }
  },
  methods: {
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    updateAccountInfo() {
      if (this.$refs.profileForm.validate()) {
        let memberObj = this.memberInfo
        this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, {memberObj})
      }
    },
    updateDetailResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        this.getMemberDetail()
        dialog.message.push(locale.getMessage(`message.memberAccountDetailUpdated`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    },
    changePasswordResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        this.closeChangePasswordDialog()
        dialog.message.push(locale.getMessage(`message.memberPasswordUpdated`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    },

    openChangePasswordDialog() {
      this.changePasswordDialogShow = true
    },
    closeChangePasswordDialog() {
      this.changePasswordDialogShow = false
    },
    updateMemberEmail() {
      let memberEmail = this.memberInfo.email.value
      this.$store.dispatch(`${MEMBER_UPDATE_EMAIL}`, {memberEmail})
    },
    verifyMemberEmail() {
      let verificationObj = {
        type: SHARED.EMAIL,
        value: this.memberInfo.email.value,
        language: uiHelper.getCookie('language'),
        domain: uiHelper.getHostname()
      }
      this.updateMemberEmail()
      this.sendVerificationEmail(verificationObj)
    },
    sendVerificationEmail(verificationObj) {
      this.$store.dispatch(`${MEMBER_SEND_CONTACT_VERIFICATION_EMAIL}`, {verificationObj})
    },
    sendContactVerificationEmailResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.memberVerificationEmailSent`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    }
  }
}
</script>
<style lang="scss">
@media (max-width: 959px) {
  .profile-page {
    hr {
      display: none;
    }

    .v-card {
      box-shadow: none;
    }
  }
  .page-title-container {
    display: none;
  }
}
</style>
